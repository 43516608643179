<template>
  <div class="l-wrapper">
    <div class="q-pa-md">
      <q-btn
        unelevated
        class="full-width q-mb-sm"
        color="grey-8"
        label="Filter"
      ></q-btn>
      <q-input
        outlined
        label="Cari Nama Perusahaan"
        v-model="options.searchTerm"
      ></q-input>
    </div>
    <q-scroll-area class="l-grow">
      <q-list separator> </q-list>
    </q-scroll-area>
    <q-dialog position="bottom">
      <q-card style="min-width:300px;"></q-card>
    </q-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { onMounted, ref, computed, reactive } from "vue";
export default {
  setup() {
    const { data, router, route, getVisitMap, $q } = useManager();

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getVisitMap();

        options = JSON.parse(route.params.filter);
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    let options = reactive({});
  },
};
</script>
